import FlipCard from "../ui/FlipCard/FlipCard";
import pablo from "../../assets/img/pablo.jpg";
import rodrigo from "../../assets/img/rodrigo.jpg";
import juan from "../../assets/img/juan.jpg";
import javier from "../../assets/img/javier.jpg";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Translation } from "../../interfaces/types";

import styles from "./TeamGallery.module.scss";

interface Props {
  gsap: GSAP;
  t: Translation;
  width: number | undefined;
}

const TeamGallery = ({ gsap, t, width }: Props) => {
  // const [animationCreated, setAnimationCreated] = useState(false);
  const { Gallery } = styles;
  // const secondArticle = useRef(null);

  // useLayoutEffect(() => {
  //   if (!animationCreated && width && width > 768) {
  //     gsap.to(secondArticle.current, {
  //       yPercent: "-30",
  //       scrollTrigger: {
  //         trigger: secondArticle.current,
  //         start: "top bottom",
  //         end: "bottom top",
  //         scrub: true,
  //       },
  //     });
  //     setAnimationCreated(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [width]);

  const firstGalleryCards = [
    {
      backTitle: "Pablo Sukmann",
      backSubTitle: "PARTNER, BUSINESS",
      cardImage: pablo,
      textContent: [
        t("home.gallery.pablo.text1"),
        t("home.gallery.pablo.text2"),
        t("home.gallery.pablo.text3"),
      ],
    },
    {
      backTitle: "Javier Kreiner",
      backSubTitle: "PARTNER, TECH",
      cardImage: javier,
      textContent: [
        t("home.gallery.javier.text1"),
        t("home.gallery.javier.text2"),
        t("home.gallery.javier.text3"),
      ],
    },
  ];

  const secondGalleryCards = [
    {
      backTitle: "Rodrigo Oroz",
      backSubTitle: "COO & CFO",
      cardImage: rodrigo,
      textContent: [
        t("home.gallery.rodrigo.text1"),
        t("home.gallery.rodrigo.text2"),
        t("home.gallery.rodrigo.text3"),
        t("home.gallery.rodrigo.text4"),
      ],
    },
    {
      backTitle: "Juan Francisco Politi",
      backSubTitle: "PARTNER",
      cardImage: juan,
      textContent: [
        t("home.gallery.juan.text1"),
        t("home.gallery.juan.text2"),
        t("home.gallery.juan.text3"),
        t("home.gallery.juan.text4"),
      ],
    },
  ];

  return (
    <section className={`${Gallery} gallery`}>
      <Row>
        {firstGalleryCards.map((props) => {
          const { textContent, ...rest } = props;
          return (
            <Col md={6} key={rest.backTitle}>
              <FlipCard {...rest}>
                <>
                  {textContent.map((text, index) => {
                    return (
                      <p key={index} className="dont-animate">
                        {text}
                      </p>
                    );
                  })}
                </>
              </FlipCard>
            </Col>
          );
        })}
      </Row>
      <Row>
        {secondGalleryCards.map((props) => {
          const { textContent, ...rest } = props;
          return (
            <Col md={6} key={rest.backTitle}>
              <FlipCard {...rest}>
                <>
                  {textContent.map((text, index) => {
                    return (
                      <p key={index} className="dont-animate">
                        {text}
                      </p>
                    );
                  })}
                </>
              </FlipCard>
            </Col>
          );
        })}
      </Row>

      {/* <article>
        {firstGalleryCards.map((props) => {
          const { textContent, ...rest } = props;
          return (
            <FlipCard {...rest} key={rest.backTitle}>
              <>
                {textContent.map((text, index) => {
                  return (
                    <p key={index} className="dont-animate">
                      {text}
                    </p>
                  );
                })}
              </>
            </FlipCard>
          );
        })}
      </article> */}
      {/* <article ref={secondArticle}>
        {secondGalleryCards.map((props) => {
          const { textContent, ...rest } = props;
          return (
            <FlipCard {...rest} key={rest.backTitle}>
              <>
                {textContent.map((text, index) => {
                  return (
                    <p key={index} className="dont-animate">
                      {text}
                    </p>
                  );
                })}
              </>
            </FlipCard>
          );
        })}
      </article> */}
    </section>
  );
};

export default TeamGallery;
